import { apiGetUserToken } from '@/api/users';
import { saveUserTokenToLocalStorage } from '@/utils/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  const email = to.query.email;
  if (!email) {
    return navigateTo('/signup');
  }
  try {
    const authToken = await apiGetUserToken(to.query.email as string);

    if (authToken) {
      saveUserTokenToLocalStorage(authToken);
    }
  } catch (e) {
    console.log(e);
  }
  return navigateTo('/');
});
